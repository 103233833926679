import * as React from "react";
import { Link } from "gatsby";
import * as style from "./FeaturedPosts.style.module.scss";

const FeaturedPosts = ({ featuredPosts }) => {
  return (
    <React.Fragment>
      <p className={style.recommended}>Featured Posts</p>
      <ul className={style.featured_posts}>
        {featuredPosts.map((featuredPost) => (
          <li className={style.featured_posts__post} key={featuredPost.id}>
            <Link
              to={`/${featuredPost.fields.source}/${featuredPost.frontmatter.slug}`}
            >
              {featuredPost.frontmatter.title}
            </Link>
          </li>
        ))}
      </ul>
    </React.Fragment>
  );
};

export default FeaturedPosts;
